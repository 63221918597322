/* src/components/OffCanvasMenu.css */
.off-canvas-menu {
  z-index: 9999;
  position: fixed;
  top: 0;
  right: -250px; /* Move off-canvas menu off the viewport */
  width: 250px;
  height: 100%;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  transition: right 0.3s;
}

.off-canvas-menu.open {
  right: 0; /* Show the off-canvas menu when open */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.close-image {
  margin: 15px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.off-canvas-menu ul {
  list-style: none;
  padding: 10px;
  font-size: 30px;
}

.off-canvas-menu li {
  margin: 10px;
}

.off-canvas-menu a {
  text-decoration: none;
  color: white;
  font-weight: 800;
  font-family: Arial, Helvetica, sans-serif;
}

.toggle-button {
  margin: 10px;
  padding: 10px;
  background: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.toggle-button:focus {
  outline: none;
}
